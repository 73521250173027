import React, {useRef, useState, useEffect, useMemo} from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useTheme } from '@mui/material/styles';
import { useSpring, animated } from '@react-spring/web';
import {
  Typography,
  Box,
  Grid,
  Container
} from '@mui/material';
import { useValue } from 'context/ContextProvider';
import { ExpandableTypography } from 'components/ExpandableTypography';

export const SectionAbout = ({section, index}) => {
  const theme = useTheme();
  const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [showText, setShowText] = useState(false);

  const {
    state: { currentRestaurant},
    dispatch,
  } = useValue();

  const sectionName = useMemo(() => section.title.toLowerCase(), [section.title]);
  useEffect(() => {
    // Dispatch an action to add the ref to the global context
    dispatch({
      type: 'SET_SECTION_REF',
      payload: { name: sectionName, ref: ref }
    });

    // Cleanup function to remove the ref from the global context
    return () => {
      dispatch({
        type: 'REMOVE_SECTION_REF',
        payload: sectionName
      });
    };
  }, [currentRestaurant]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Set state based on whether the component is intersecting
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null, // null means it observes changes in the viewport
        rootMargin: '0px',
        threshold: 0.1, // Trigger when 10% of the element is in view
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref]);

  // Style object for fade-in effect
  const textFadeInStyle = useSpring({
    opacity: showText ? 1 : 0,
    transform: showText ? 'translateY(0)' : 'translateY(20px)',
    delay: 100, // Optional delay to ensure image animation is noticed
  });
    // Determine animation direction based on index
const fromDirection = index % 2 === 0 ? '-100%' : '100%';

    // Animation for the image
   
const imageAnimation = useSpring({
    from: { transform: `translate3d(${fromDirection}, 0, 0)`, opacity: 0 },
    to: {
      transform: isVisible ? 'translate3d(0, 0, 0)' : 'translate3d(-100%, 0, 0)',
      opacity: isVisible ? 1 : 0,
    },
    config: { mass: 1, tension: 210, friction: 18 },
    onRest: () => setShowText(isVisible), // Callback when animation is done
  });

    // Background color animation based on showText
  const backgroundColorSpring = useSpring({
    backgroundColor: isVisible ? theme.palette.primary.dark : 'transparent',
    config: { duration: 600 },
    minHeight:'100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    p: 2,
  });
  return (
    <animated.div
    ref={ref} // Apply ref to the Box component
    style={backgroundColorSpring}
  >
                <Container>
                <Grid container spacing={4} >
                    <Grid item sm={12} md={4} sx={{display:'flex', alignItems:'flex-start',justifyContent:'center'}}>
                        <animated.div style={imageAnimation}>
                        <Grid container spacing={4} sx={{maxWidth:600}}>
                                <Grid item xs={12} sx={{display:'flex', alignItems:'center', justifyContent:'center',p:2}}>
                                    <Typography variant='h2' sx={{color: theme.palette.secondary.main  ,fontFamily: '"Playfair Display", serif',
                                        fontSize:'clamp(4rem, 6vw, 5rem)',
                                        fontWeight: 'normal',
                                        textTransform: 'none',}}>
                                        {section.title}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                    <LazyLoadImage  
                                                srcSet={section.imageSrcSet}
                                                sizes="(max-width: 400px) 100vw, (max-width: 900px) 50vw, 33.3vw"
                                                src={section.image}
                                                effect="blur"
                                                placeholderSrc={section.placeholder}
                                                style={{width:'100%'}}

                                    />
                                </Grid>
                            
                            </Grid>
                           
                        </animated.div>
                    </Grid>
                   
                    <Grid item sm={12} md={4} sx={{display:'flex', alignItems:'flex-start', justifyContent:'center'}}>
                        <animated.div style={textFadeInStyle}>
                            <Grid container spacing={4} sx={{maxWidth:600}}>
                                <Grid item xs={12} sx={{display:'flex', alignItems:'center', justifyContent:'center',p:2}}>
                                    <Typography variant='h3' sx={{color: theme.palette.background.main  ,fontFamily: '"Playfair Display", serif',
                                        fontSize:'clamp(2rem, 6vw, 3rem)',
                                        fontWeight: 'bold',
                                        textTransform: 'none',}}>
                                        {section.subtitle1}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                    <ExpandableTypography  text={section.body1}/>
                                </Grid>
                            
                            </Grid>
                        </animated.div>
                    </Grid>
                    <Grid item sm={12} md={4} sx={{display:'flex', alignItems:'flex-start', justifyContent:'center'}}>
                        <animated.div style={textFadeInStyle}>
                            <Grid container spacing={4} sx={{maxWidth:600}}>
                                <Grid item xs={12} sx={{display:'flex', alignItems:'center', justifyContent:'center',p:2}}>
                                    <Typography variant='h3' sx={{color: theme.palette.background.main  ,fontFamily: '"Playfair Display", serif',
                                        fontSize:'clamp(2rem, 6vw, 3rem)',
                                        fontWeight: 'bold',
                                        textTransform: 'none',}}>
                                        {section.subtitle2}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                    <ExpandableTypography  text={section.body2}/>
                                </Grid>
                            
                            </Grid>
                        </animated.div>
                    </Grid>
                </Grid>
                </Container>
            </animated.div>
          
        );
}
