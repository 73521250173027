import React from 'react';
import { Button, CircularProgress } from '@mui/material';

export const BasicButton = ({
  children,
  variant = 'contained',
  color = 'primary',
  size = 'medium',
  startIcon,
  endIcon,
  loading = false,
  disabled = false,
  disableRipple = false,
  href,
  to,
  component,
  onClick,
  sx = {},
  ...otherProps
}) => {
  // Determine if the button should be disabled
  const isDisabled = disabled || loading;

  // Custom onClick handler to prevent navigation when disabled
  const handleClick = (event) => {
    if (isDisabled) {
      event.preventDefault();
      event.stopPropagation();
    } else if (onClick) {
      onClick(event);
    }
  };

  // Props to pass to the Button component
  const buttonProps = {
    variant,
    color,
    size,
    startIcon: loading ? null : startIcon,
    endIcon: loading ? null : endIcon,
    disabled: isDisabled,
    disableRipple,
    component,
    onClick: handleClick,
    sx: {
      //textTransform: 'none',
      borderRadius: 30,
      paddingTop:1,
      paddingBottom:1,
      paddingLeft: 2,
      paddingRight: 2,
      minHeight: size === 'large' ? 64 : 50,
      minWidth: size === 'large' ? 200 : 120,
      fontWeight: 'bold',
      // Custom hover effect
      '&:hover': {
        backgroundColor: variant === 'contained' ? `${color}.dark` : undefined,
      },
      // Custom focus effect
      '&:focus': {
        boxShadow: '0 0 0 2px rgba(0, 0, 0, 0.2)',
      },
      ...sx,
    },
    ...otherProps,
  };

  // Set href or to prop based on the component
  if (component) {
    if (to) {
      buttonProps.to = to;
    }
  } else if (href) {
    buttonProps.href = href;
  }

  return (
    <Button {...buttonProps}>
      {loading ? (
        <CircularProgress size={24} color="inherit" />
      ) : (
        children
      )}
    </Button>
  );
};
