import React, { useState } from 'react';
import { Typography, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { BasicButton } from './Buttons/BasicButton';
export const ExpandableTypography = ({ text }) => {
    const theme = useTheme();
    const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <Typography
        variant='subtitle1'
         
        sx={{color: theme.palette.grey.light ,
            fontSize:'clamp(.85rem, 6vw, 1rem)',
            fontWeight: 'normal',
            textTransform: 'none',}}
      >
        {isExpanded ? text : `${text.substring(0, 400)}...`}
      </Typography>
      {text.length > 100 && (
        <BasicButton onClick={toggleExpand} size ='small'>
          {isExpanded ? 'Leggi meno' : 'Leggi di più'}
        </BasicButton>
      )}
    </div>
  );
};