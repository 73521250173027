import React, {useEffect, useState, useMemo, useRef} from 'react'

import { useTheme } from '@mui/material/styles';
import {
  Typography,
  Grid,
  Link,

} from '@mui/material';


export const PrivacyLinks = () => {

    const theme = useTheme();

  return (
    <Grid container spacing={2} >
        <Grid item xs={12}sx={{display:'flex', alignItems:'center', justifyContent:'center', flexWrap:'wrap'}}>
            <Typography variant='p'>West Pier Srl PIVA: 01672620448</Typography>
        </Grid>
        <Grid item xs={12} sx={{color:theme.palette.grey.main, display:'flex', justifyContent:{xs:'center', sm:'center'} ,gap:1, flexWrap:'wrap'}}>
            <Link href={'/privacy-policy'} underline="none" variant='p' color={'inherit'} sx={{':hover':{ color:theme.palette.secondary.main} }}>
              Privacy Policy
            </Link>
       
            <Link href={'/cookie-policy'} underline="none" variant='p' color={'inherit'} sx={{':hover':{ color:theme.palette.secondary.main} }}>
              Cookie Policy
            </Link>
    
            <Link href={'/note-legali'} underline="none" variant='p' color={'inherit'} sx={{':hover':{ color:theme.palette.secondary.main}}}>
              Termini e condizioni
            </Link>
        </Grid>
    </Grid>

  )
}
