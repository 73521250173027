import { TextField, InputAdornment } from '@mui/material';
import React from 'react';
import { useTheme } from '@mui/material/styles';

export const BasicTextField = ({
  id,
  label,
  value,
  name,
  type = 'text',
  inputRef = undefined,
  onChange,
  startIcon,
  endIcon, // Add endIcon prop
  error = false,
  helperText = '',
  ...params
}) => {
  // Extract InputProps and InputLabelProps from params
  const { InputProps = {}, InputLabelProps = {}, ...restParams } = params;
  const theme = useTheme();

  // Combine startAdornment from startIcon or InputProps
  const combinedStartAdornment = startIcon ? (
    <InputAdornment position="start" sx={{ marginRight: 1, marginBottom: 0 }}>
      {startIcon}
    </InputAdornment>
  ) : (
    InputProps.startAdornment
  );

  // Combine endAdornment from endIcon or InputProps
  const combinedEndAdornment = endIcon ? (
    <InputAdornment position="end" sx={{ marginLeft: 1, marginTop:2}}>
      {endIcon}
    </InputAdornment>
  ) : (
    InputProps.endAdornment
  );

  // Determine if startAdornment or endAdornment is present
  const hasStartAdornment = !!(startIcon || InputProps.startAdornment);
  const hasEndAdornment = !!(endIcon || InputProps.endAdornment);

  const filledBackgroundColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey[200] // Default filled background color in light mode
      : theme.palette.grey[700]; // Default filled background color in dark mode


  return (
    <TextField
      {...restParams}
      InputProps={{
        disableUnderline: true,
        ...InputProps, // Spread existing InputProps
        startAdornment: combinedStartAdornment,
        endAdornment: combinedEndAdornment,
        sx: {
          // Merge existing sx styles
          ...InputProps.sx,
          // Custom styles
          borderRadius: 30,
          display: 'flex',
          alignItems: 'center',
          paddingLeft: hasStartAdornment ? 0 : undefined,
          paddingRight: hasEndAdornment ? 0 : undefined,
          transition: 'background-color 0.3s, color 0.3s',
          '& .MuiInputAdornment-root': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            paddingTop: 0,
            paddingBottom: hasStartAdornment || hasEndAdornment? 2 : undefined,
            paddingLeft: hasStartAdornment  ? 1: undefined,
            paddingRight: hasEndAdornment ? 1 : undefined,
            '& .MuiSvgIcon-root': {
              fontSize: '1.5rem',
              color: 'inherit', // Allow the icon to inherit color
              transition: 'color 0.3s',
            },
          },
          '& .MuiInputBase-input': {
            paddingTop: hasStartAdornment || hasEndAdornment ? 0 : 2,
            paddingBottom: hasStartAdornment || hasEndAdornment ? 0 : 2,
            margin: 0,
            //backgroundColor: error ? theme.palette.error.light : undefined,
            borderColor: error ? theme.palette.error.main : undefined,
            color: error ? theme.palette.error.main : undefined,
            height: '100%',
            boxSizing: 'border-box',
          },
          // Styles when focused
          '&.Mui-focused': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)', // Optional: Change background on focus
            '& .MuiInputAdornment-root .MuiSvgIcon-root': {
              color: error ? 'error.main' : 'primary.main', // Change icon color when focused
            },
          },
          // Rest of your styles
          '&:before': {
            borderBottom: error ? `1px solid ${theme.palette.error.main}` : 'none',
          },
          '&:after': {
            borderBottom: error ? `1px solid ${theme.palette.error.main}` : 'none',
          },
          '&:hover:not(.Mui-disabled):before': {
            borderBottom: 'none',
          },
          '& .MuiInputAdornment-root .MuiSvgIcon-root': {
            fontSize: '1.5rem',
            color: error ? 'error.main' : 'inherit', // Change icon color when error
            transition: 'color 0.3s',
          },
          // Autofill override styles
          '& input': {
            '&:-webkit-autofill': {
              WebkitBoxShadow: `0 0 0 1000px ${filledBackgroundColor} inset !important`,
              WebkitTextFillColor: `${theme.palette.text.primary} !important`,
              caretColor: `${theme.palette.text.primary} !important`,
              borderRadius: 'inherit',
            },
            '&:-webkit-autofill:focus': {
              WebkitBoxShadow: `0 0 0 1000px ${filledBackgroundColor} inset !important`,
            },
            '&:-webkit-autofill:hover': {
              WebkitBoxShadow: `0 0 0 1000px ${filledBackgroundColor} inset !important`,
            },
            '&:-webkit-autofill:active': {
              WebkitBoxShadow: `0 0 0 1000px ${filledBackgroundColor} inset !important`,
            },
            // For Firefox
            '&:-moz-autofill': {
              boxShadow: `0 0 0 1000px ${filledBackgroundColor} inset !important`,
              textFillColor: `${theme.palette.text.primary} !important`,
              caretColor: `${theme.palette.text.primary} !important`,
            },
          },
          },
      }}
      InputLabelProps={{
        ...InputLabelProps,
        shrink: false,
      }}
      placeholder={label}
      size="small"
      name={name}
      type={type}
      inputRef={inputRef}
      label="" // Remove the label to prevent floating
      id={id}
      fullWidth
      variant="filled"
      error={error}
      helperText={helperText}
      {...(value !== undefined ? { value } : {})}
      {...(onChange ? { onChange } : {})}
    />
  );
};

