import React from 'react';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const Heading =({ mainProps, optionalProps = {}, size, text, color, type, fontWeight, fontSize })=> {
  return (
    <Typography
        {...optionalProps}
        {...mainProps}
        variant={type}
        sx={{
            fontFamily: '"Playfair Display", serif',
            fontSize: fontSize || 'clamp(3rem, 6vw, 5rem)', // Adjusts between 2rem and 5rem based on viewport width
            fontWeight: 'bold',
            textTransform: 'none',
            fontWeight: fontWeight || 'bold',
            color:color
          
          }}
        >
          {text}
        </Typography>
  );
}