import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useValue } from 'context/ContextProvider'
import { BasicTextField } from 'components/TextFields/BasicTextField';

const PasswordField = ({passwordRef, id, name, label, error, helperText, onChange}) => {
  const [showPassword, setShowPassword] = useState(false);

  const {
    state: { userError},
    dispatch,
  } = useValue();

  const handleClick = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
  };

  return (
    <BasicTextField
      margin="normal"
      variant="filled"
      id={id}
      label={label}
      name = {name}
      type={showPassword ? 'text' : 'password'}
      fullWidth
      inputRef={passwordRef}
      required
      helperText={helperText}
      error = {error}
      onChange={onChange}
      endIcon={<IconButton aria-label="view password" onClick={handleClick} onMouseDown={handleMouseDown}>
      {showPassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>}
      
    />
  );
};

export default PasswordField;