import React, { useState } from 'react';

import {Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { BasicButton } from './BasicButton';
export const BookButton = ({ mainProps, optionalProps = {}, url, text }) => {


 
  return (
      <BasicButton
      {...optionalProps}
      {...mainProps}
      variant={"contained"}
      
      component={Link}
      size="medium"
      
      to={url}
      color={"secondary"}
        >
      {text}
    </BasicButton>
  );
};
